.FontSizeSelector {
    // padding: 55px;
    padding: 20px 0;
    background-color: #f3f3f3;

    :global {
        .social-margin {
            padding-top: 16px;
        }
        .fontFlex {
            display: flex;
            justify-content: end;
            align-items: center;
        }
        .fontSizeSe {
            display: flex;
            align-items: center;
            padding-left: 180px;

            span {
                font-size: 12px;
                padding: 5px 2px 2px 2px;
                color: #333;
            }

            .active {
                background: #949494;
                border-radius: 4px 4px 4px 4px;
                color: #fff !important;
            }
            .selectItem {
                cursor: pointer;
                float: left;
                font-size: 15px !important;
                line-height: 16px;
                display: block;
                font-weight: bold;
                padding: 5px;
                color: #999999;
            }
            .selectItem:hover {
                color: #3b3b3b;
                transition: 0.25s ease-out;
                text-decoration: underline;
            }
            .small {
                font-size: 10px !important;
            }
            .medium {
            }
            .large {
                font-size: 20px !important;
            }
        }
    }
}
