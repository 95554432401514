.iconDiv {
    :global {
        // .ant-float-btn {
        //     bottom: 78px;
        // }
        .ant-float-btn-body {
            display: block !important;
            background-color: rgb(0 0 0 / 0%) !important;
        }
        .ant-float-btn-default {
            background-color: #ffffff00 !important;
        }
        .ant-float-btn-content {
            padding: 0 !important;
        }
        .ant-float-btn-icon {
            width: 100% !important;
        }
    }
}
