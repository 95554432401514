.footerNav {
    width: 100%;
    background-color: #414140;
    color: #fff;
    font-size: 12px;
    padding: 10px;

    :global {
        .footerContent {
            max-width: 1140px;

            .btn {
                color: #fff;
                font-size: 12px;
                text-decoration: none;
            }
            .btn:hover {
                color: #ffd503;
                text-decoration: underline;
            }

            // .footer_copyright {
            //     text-align: right;
            // }
        }
    }
}
