$nav-underline-active-color: #fecf13;
.headerNav {
    // padding-bottom: 5px;

    :global {
        .ant-btn-link {
            color: #333;
        }
        .ant-btn-link:hover {
            color: #333;
        }
        .langBtn {
            height: 46px;
            line-height: 46px;
            color: #333;
            // font-size: 11.375px;
            text-decoration: none;
            border-radius: inherit;
            border-bottom: 5px solid transparent;
        }
        .langBtn:hover {
            color: #333;
            text-decoration: none;
            border-bottom: 5px solid #fecf13;
        }

        .hkjcLogo {
            // display: block;
            margin: 9px 0px 11px 0px;
        }

        .ant-menu-horizontal {
            border-color: transparent;
        }
        .ant-menu-item,
        .ant-menu-item-selected {
            color: #0c2d69 !important;
            font-size: 0.875em;
        }

        .ant-menu-item-only-child::after {
            border-bottom-width: 5px !important;
        }

        .ant-menu-item-active::after,
        .ant-menu-item-selected:after {
            border-bottom-width: 5px !important;
            border-bottom-color: #fecf13 !important;
        }
    }
}

.drawerWrap {
    :global {
        .ant-drawer-content-wrapper {
            // width: 100% !important
            max-width: 440px;
            min-width: 140px;
        }
    }
}

@media (min-width: 1200px) {
    .headerFlex {
        width: 1170px;
    }
}

@media (min-width: 992px) and (max-width: 1199px) {
    .headerFlex {
        width: 970px;
    }
}

// .headerMNav {
// }
