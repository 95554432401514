@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    color: #333333;
    font-family: var(--font-family);
    font-size: 15px;
    line-height: 1.8em;
    font-weight: normal;
    margin: 0%;
}
body p {
    margin-bottom: 10px;
}
body h1 {
    font-size: 1.8em;
    color: #0c2d69;
    text-align: center;
    width: 100%;
    margin: 20px 0px 20px 0px;
    font-weight: 700;
}

body h2 {
    font-size: 1.5em;
    color: #0c2d69;
    text-align: center;
    line-height: 1.3em;
    font-weight: 700;
}

body h3 {
    color: #0c2d69;
    font-size: 1.125em;
    line-height: 1.5em;
    font-weight: 600;
    margin: 10px 0px 10px 0px;
}

body h4 {
    color: #fff;
    background: #0c2d69;
    font-size: 0.9375em;
    line-height: 1.6em;
    font-weight: bold;
    padding: 5px;
}

body menu,
ol,
ul {
    list-style: auto;
    padding: revert;
}
